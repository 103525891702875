<template>
   <div class="upload-container" :class="containerClasses" ref="uploadContainer">
     <input type="file" style="display:none" ref="fileControl" accept="image/*" multiple>
     <div class="title" :class="{ 'disabled': disdabled }"><a href="javascript:void(0);" @click="choosePictures">拖拽文件到此处或点击<va-icon name="cloud_upload"/>上传文件</a></div>
     <div class="file-list">
       <ul class="">
         <li v-for="file in files" :key="file.key">
            <span @click="fileItemClicked(file)">{{file.fileName}}</span>
            <va-icon size="1.3rem" name="delete" class="remove" @click="removeSingleFile(file.key)"/>
         </li>
       </ul>
     </div>
   </div>
   <div class="picture-viewer" v-if="!hideImageViewer" @click="hideImageViewer = true">
     <div class="mask"></div>
     <div class="pic-container">
       <img :src="imageBaseUrl"/>
     </div>
   </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, onUpdated, reactive, PropType } from 'vue'
import { FileItem } from '@/model'

declare global {
    interface FileList {
        forEach(callback: (f: File) => void) : void;
    }
}

export default defineComponent({
  name: "picture-uploader",
  props: {
    disdabled: { type: Boolean, default: false },
    dragContainer: { type: HTMLDivElement },
    files: { type: Array as PropType<FileItem[]>, default: [] },
    deletedFiles: { type: Array, default: [] as Array<FileItem> },
    dirty: { type: Boolean }
  },
  emits: ['update:files', 'update:deletedFiles', 'update:dirty'],
  setup(props, {emit}) {
    const fileControl = ref<HTMLInputElement>()
    const uploadContainer = ref<HTMLDivElement>()
    const acceptanceExtensionTypes = ['.jpg', '.jpeg', '.png', '.gif', '.bmp']
    const containerClasses = ref({
      'drag-enter': false,
      'drag-leave': false,
    })
    function choosePictures() {
      if(props.disdabled) {
        return
      }

      fileControl.value?.click()
    }

    let dragEventInited = false
    onUpdated(() => {
      if(!dragEventInited) {
        initDragEvent()
        dragEventInited = true
      }
    })

    onMounted(() => {
      if(props.files) {
        props.files.push(...props.files as Array<FileItem>)
      }
    })

    const hideImageViewer = ref(true)
    const imageBaseUrl = ref('')

    function initDragEvent() {
      let container = uploadContainer.value
      if(props.dragContainer) {
        container = props.dragContainer
      }

      if(container) {
        const dragMaskDiv = document.createElement('DIV')
        dragMaskDiv.className = 'drag-mask'
        dragMaskDiv.style.cssText = 'position:absolute;top:0px;right:0px;bottom:0px;left:0px;'
        

        container.ondragenter = function(ev) {
            if(!container?.querySelector('div.drag-mask')) {
              container?.appendChild(dragMaskDiv)
            }
            containerClasses.value['drag-enter'] = true
            containerClasses.value['drag-leave'] = false
        }

        dragMaskDiv.ondragleave = function(ev) {
          const maskDiv = container?.querySelector('div.drag-mask')
          if(ev.target === maskDiv) {
            containerClasses.value['drag-enter'] = false
            containerClasses.value['drag-leave'] = true
            maskDiv && container?.removeChild(maskDiv)
          }
        }

        dragMaskDiv.ondragover = function(ev) {
          ev.preventDefault()
        }

        dragMaskDiv.ondrop = function(ev) {
          const files = ev.dataTransfer?.files;
          if(files?.length ?? 0 > 0) {
            files?.forEach(file => {
              if(acceptanceExtensionTypes.some(type => file.name.endsWith(type))) {
                readImage(file)
              }
            })
          }

          const maskDiv = container?.querySelector('div.drag-mask')
          if(ev.target === maskDiv) {
            containerClasses.value['drag-enter'] = false
            containerClasses.value['drag-leave'] = true
            maskDiv && container?.removeChild(maskDiv)
          }
           ev.preventDefault()
        }
      }

      if(fileControl.value) {
        fileControl.value.onchange = function(ev) {
          emit('update:dirty', true)
          const ctrl = ev.target as HTMLInputElement
          if(ctrl) {
            ctrl.files?.forEach(file => {
              readImage(file)
            })
          }
        }
      }
    }

    function readImage(imgFile : File) {
      if(!props.files.some(f => f.fileName === imgFile.name && f.mime === imgFile.type)) {
        const reader  = new FileReader();
        reader.onload = () => {
          props.files.push({
            key: Math.random().toString(32).substring(2),
            fileName: imgFile.name,
            fileSize: imgFile.size.toString(),
            mime: imgFile.type,
            fileContentBase64: reader.result as string,
          } as FileItem)
          emit('update:files', props.files)
        }

        if (imgFile) {
          reader.readAsDataURL(imgFile);
        }
      }
    }

    function removeSingleFile(key: string) {
      const deletedFiles = props.files.splice(props.files.findIndex((f: any) => f.key === key),1)
      emit('update:files', props.files)
      emit('update:dirty', true)
      
      if(deletedFiles[0].id) {
        props.deletedFiles.push(deletedFiles[0])
      }
      emit('update:deletedFiles', props.deletedFiles)
    }

    function fileItemClicked(file: FileItem) {
      hideImageViewer.value = false
      imageBaseUrl.value = file.fileContentBase64
    }
    

    return {
      containerClasses,
      fileControl,
      uploadContainer,
      choosePictures,
      fileItemClicked,
      removeSingleFile,
      hideImageViewer,
      imageBaseUrl,
    }
  },
})
</script>

<style lang="scss" scoped>
  .upload-container
  {
    position:relative;
    padding: 1rem;
    margin-bottom: .5rem;
    border: 2px solid var(--va-input-bordered-color);
    &.drag-enter {
      border-color: #154ec1;
      border-style: dashed;
    }

    &.drag-leave {
      border-color: var(--va-input-bordered-color);
       border-style: solid;
    }


    .title
    {
      text-align: center;
      font-size: .8rem;
      &.disabled {
        a {
          cursor: default;
          opacity: 0.5;
        }
      }
      
      i {
           margin-top: -0.2rem;
      }
    }

    .file-list {
      ul{
        padding: 0;
        margin: 0;
        margin-top: 1rem;
        li{
          cursor: pointer;
          line-height: 1.5;
          padding: .2rem .5rem;
          word-break: break-word;
          @media (min-width: 768px) {
            i.remove {
            display: none;
            }
            &:hover{
              border-radius: .3rem;
              background-color: #edf0fc;
              i.remove{
                display: inline;
              }
            }
          }
        }
      }
    }
  }

  .picture-viewer
  {
    .mask{
      position: fixed;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      background: #00000080;
      z-index: 5000;
    }

    .pic-container {
      position: fixed;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5100;
      img{
        max-width: 100%;
      }
    }
  }
</style>
